'use client'

import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'

import {cx} from '@atorie/core/cva'
import {useCallbackRef} from '@atorie/hooks'
import {useVirtualizer} from '@tanstack/react-virtual'

interface VirtualListProps<T = unknown> {
  /** The items to virtualize.*/
  items: T[]
  /** The total number of items to virtualize.*/
  count: number
  estimateSize?: () => number
  /** The number of items to render outside of the visible area.*/
  overscan: number
  renderItem: (item: T, idx: number, items: T[]) => React.ReactNode
  className?: string
  onLastItem?: () => void

  isLoading?: boolean

  /** The number of placeholder items to render when the `isLoading` prop is `true` */
  placeholderItemsCount?: number

  /** Represents an item to use as a placeholder for each item when the `isLoading` property is `true`, the placeholderItemsCount must be >0*/
  renderPlaceholderItem?: (idx: number) => React.ReactNode

  paddingEnd?: number

  paddingStart?: number

  /** Render an componet when items is empty */
  renderEmptyList?: () => React.ReactNode

  renderEnd?: () => React.ReactNode

  /** Render a component on top of all items */
  renderFirst?: () => React.ReactNode

  onScroll?: (scroll: {
    direction: 'forward' | 'backward' | null
    offset: number | null
    rect: {width: number; height: number} | null
  }) => void
}

const DEFAULT_ESTIMATED_VIRTUALIZER_HEIGHT_FN = () => 160

export type VirtualListElement = {
  scrollTo: (...args: Parameters<HTMLDivElement['scrollTo']>) => void
}

function VirtualListInner<T = unknown>(
  {
    items,
    overscan = 2,
    renderItem,
    className,
    count,
    onLastItem,
    estimateSize = DEFAULT_ESTIMATED_VIRTUALIZER_HEIGHT_FN,
    paddingEnd = 100,
    paddingStart = 0,
    renderEnd = () => null,
    renderEmptyList = () => null,
    renderFirst = () => null,
    placeholderItemsCount = 0,
    renderPlaceholderItem = () => null,
    isLoading = false,
    onScroll,
  }: VirtualListProps<T>,
  ref: ForwardedRef<VirtualListElement>,
) {
  const onLastItemFn = useCallbackRef(onLastItem)
  const containerRef = useRef<HTMLDivElement>(null)
  const totalItems = useMemo(
    () => (isLoading ? count + placeholderItemsCount : count),
    [count, isLoading, placeholderItemsCount],
  )

  const virtual = useVirtualizer({
    count: totalItems + 1, // Add one to count to render renderEnd or list empty
    estimateSize,
    getScrollElement: () => containerRef.current,
    paddingEnd,
    paddingStart,
    onChange(instance) {
      if (instance.isScrolling || instance.scrollOffset === 0) {
        onScroll?.({
          direction: instance.scrollDirection,
          offset: instance.scrollOffset,
          rect: instance.scrollRect,
        })
      }
    },
    overscan,
  })

  useImperativeHandle(ref, () => {
    return {
      scrollTo: (...args: Parameters<HTMLDivElement['scrollTo']>) => {
        containerRef.current?.scrollTo(...args)
      },
    }
  })

  const virtualItems = virtual.getVirtualItems()

  useLayoutEffect(() => {
    const lastItem = virtualItems[virtualItems.length - 1]

    if (!lastItem) {
      return
    }

    if (lastItem.index >= items.length - 1) {
      onLastItemFn?.()
    }
  }, [items.length, onLastItemFn, virtualItems])

  return (
    <div
      className={cx(
        'no-scrollbar isolate size-full overflow-y-auto overflow-x-hidden',
        className,
      )}
      ref={containerRef}
    >
      <div
        className={cx('relative w-full')}
        style={{height: `${virtual.getTotalSize()}px`}}
      >
        {virtualItems.map((virtualItem) => {
          const index = virtualItem.index
          const item = items[index]

          if (index === totalItems) {
            const canRenderEnd = !isLoading && count > 0
            const canRenderEmptyList = !isLoading && count === 0

            return (
              <div
                data-index={index}
                key={virtualItem.key}
                ref={virtual.measureElement}
                className={cx('absolute left-0 top-0 w-full')}
                style={{
                  transform: `translateY(${virtualItem.start ?? 0}px)`,
                }}
              >
                {canRenderEmptyList && renderEmptyList()}
                {canRenderEnd && renderEnd()}
              </div>
            )
          }

          if (isLoading && index >= count) {
            const placeholderIndex = index - count

            return (
              <div
                data-index={virtualItem.index}
                key={virtualItem.key}
                ref={virtual.measureElement}
                className={cx('absolute left-0 top-0 w-full')}
                style={{
                  transform: `translateY(${virtualItem.start ?? 0}px)`,
                }}
              >
                {renderPlaceholderItem(placeholderIndex)}
              </div>
            )
          }

          return (
            <div
              data-index={virtualItem.index}
              key={virtualItem.key}
              ref={virtual.measureElement}
              className={cx('absolute left-0 top-0 w-full')}
              style={{
                // height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start ?? 0}px)`,
              }}
            >
              {index === 0 && renderFirst()}
              {renderItem(item!, index, items)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const VirtualList = forwardRef<
  VirtualListElement,
  VirtualListProps<any>
>(VirtualListInner) as <T =unknown>(
  props: VirtualListProps<T> & {ref?: React.ForwardedRef<VirtualListElement>},
) => ReturnType<typeof VirtualListInner>

export default VirtualList
