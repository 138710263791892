'use client'

import {useRouter} from 'next/navigation'

import {StorefrontGenerated, StorefrontTypes} from '@atorie/api/types'
import {useShopifyProductByIdQuery} from '@atorie/hooks'
import {Modal, ModalOverlay} from '@atorie/ui/rac'

import ShopifyProductView from './shopify-product-modal-view'

interface ProductModalProps {
  product?: StorefrontGenerated.ProductCardFragment | null
  productId?: string | null
  imageIndex?: number
  isOpen: boolean
  alwaysRefresh?: boolean
  onClickOutside?: () => void
}

function ProductModal({
  product,
  productId,
  imageIndex = 0,
  isOpen,
  alwaysRefresh,
  onClickOutside,
}: ProductModalProps) {
  const router = useRouter()
  // Query product by productId if product is null
  const {data: fetchedProduct, isLoading} = useShopifyProductByIdQuery(
    productId ?? '',
    {
      enabled: !product && productId != null, // Only run if product is null and productId is available
    },
  )

  const productData = product || fetchedProduct
  if (isLoading && isOpen && !productData) {
    return <div>Loading...</div>
  }
  return (
    <ModalOverlay
      className={`absolute inset-0 z-40 flex min-h-svh items-center justify-center before:absolute before:inset-0 before:transition-colors before:duration-500 ${isOpen ? 'before:bg-black/50' : 'before:bg-black/0'}`}
      isOpen={isOpen}
    >
      <div
        className="absolute inset-0 isolate"
        onClick={(e) => {
          e.stopPropagation()
          onClickOutside?.()
        }}
      />
      <Modal className="z-20 flex h-[494px] w-[354px] rounded-xl sm:h-[660px] sm:w-[600px] xl:h-[800px] xl:w-[1000px]">
        <div className="flex size-full rounded-xl">
          <ShopifyProductView product={productData!} imageIndex={imageIndex} />
        </div>
      </Modal>
    </ModalOverlay>
  )
}

export default ProductModal
