'use client'

import Image from 'next/image'
import {useEffect, useRef, useState} from 'react'

import {BoundingPoly} from '@atorie/api/vision'

import {AnalyzingOverlay} from './analyzing-overlay'

export interface AutoScrollGalleryProps {
  imageUrls: string[]
  scrollSpeed?: number // pixels per second
  scrollDuration?: number // duration between images in ms
}

export function AutoScrollGallery({
  imageUrls,
  scrollDuration = 6000, // default duration between images in ms
}: AutoScrollGalleryProps) {
  const halfLength = Math.floor(imageUrls.length / 2)
  const scrollRef = useRef<HTMLDivElement>(null)
  const scrollPositionRef = useRef(0)
  const [centeredIndex, setCenteredIndex] = useState(halfLength)
  const noAnimationRef = useRef<boolean>(true)
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const [boundingPoly, setBoundingPoly] = useState<BoundingPoly | null>(null)
  const duplicatedImages = [
    ...imageUrls.slice(halfLength),
    ...imageUrls,
    ...imageUrls,
  ]

  const dummyPoly = (): BoundingPoly => {
    return {
      normalizedVertices: [
        {x: 0.25, y: 0.25}, // Top-left corner
        {x: 0.75, y: 0.25}, // Top-right corner
        {x: 0.75, y: 0.85}, // Bottom-right corner
        {x: 0.25, y: 0.85}, // Bottom-left corner
      ],
    }
  }

  useEffect(() => {
    const scrollContainer = scrollRef.current
    const imageContainer = imageContainerRef.current
    if (!scrollContainer || !imageContainer) return

    const imageWidth = Math.floor(imageContainer.getBoundingClientRect().width)
    scrollPositionRef.current = imageWidth * halfLength
    scrollContainer.scrollLeft = scrollPositionRef.current

    const animate = () => {
      setBoundingPoly(null)
      setTimeout(() => {
        setBoundingPoly(dummyPoly())
      }, 2000)

      scrollPositionRef.current += imageWidth
      const newCenteredIndex =
        Math.floor(scrollPositionRef.current / imageWidth) %
        (imageUrls.length * 2)

      if (newCenteredIndex == imageUrls.length + halfLength) {
        setTimeout(() => {
          noAnimationRef.current = true
          scrollPositionRef.current = imageWidth * halfLength
          setCenteredIndex(halfLength)
          updateScrollPosition()
        }, 1000)
      }

      setCenteredIndex(newCenteredIndex)
      updateScrollPosition()
    }

    const updateScrollPosition = () => {
      if (scrollContainer) {
        if (noAnimationRef.current && scrollPositionRef.current != 0) {
          noAnimationRef.current = false
          scrollContainer.style.scrollBehavior = 'auto'
        } else {
          scrollContainer.style.scrollBehavior = 'smooth'
        }
        scrollContainer.scrollLeft = scrollPositionRef.current
      }
    }

    const animationInterval = setInterval(animate, scrollDuration)
    setTimeout(() => {
      setBoundingPoly(dummyPoly())
    }, 2000)

    return () => clearInterval(animationInterval)
  }, [halfLength, imageUrls.length, scrollDuration])

  return (
    <div
      className={`pointer-events-none relative size-full overflow-hidden transition-opacity duration-300 ${scrollPositionRef.current > 0 ? 'opacity-100' : 'opacity-0'}`}
    >
      <div ref={scrollRef} className="no-scrollbar relative flex overflow-auto">
        <div className="w-[calc(50svw-144px)] shrink-0 sm:w-[calc(50svw-192px)]" />
        {duplicatedImages.map((url, index) => (
          <div
            key={index}
            className="relative aspect-square w-72 shrink-0 px-4 py-1 sm:w-96 sm:px-8"
            ref={index === 0 ? imageContainerRef : null}
          >
            <Image
              src={url}
              alt={`Gallery image ${(index % imageUrls.length) + 1}`}
              className={`size-full object-cover transition-all ${index == centeredIndex ? '' : 'scale-95 rounded-lg opacity-70'}`}
              width={220} // Adjust width as needed
              height={220} // Adjust height as needed
              loading="lazy"
              sizes="50vw"
            />
          </div>
        ))}
      </div>
      <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center transition-opacity duration-300">
        <div className="size-full w-72 px-3 sm:w-96 sm:px-7">
          <AnalyzingOverlay boundingPoly={boundingPoly} />
        </div>
      </div>
    </div>
  )
}
