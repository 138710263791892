'use client'

import {useParams, useRouter} from 'next/navigation'
import React, {useEffect, useMemo, useRef, useState} from 'react'

import {VisionAnalysisResult} from '@atorie/api/vision'
import {cx} from '@atorie/core/cva'
import {
  useAuthUser,
  useIsGuest,
  useUserNotificationRequestsMutation,
  useUserNotificationsBySearchId,
  useWindowSize,
} from '@atorie/hooks'
import {Button} from '@atorie/ui/buttons'
import {CroppedImage} from '@atorie/ui/images'
import {VirtualList} from '@atorie/ui/lists'
import {Text} from '@atorie/ui/typography'
import {AnalyzingOverlay} from '@atorie/ui/vision'
import {EllipsisHorizontalIcon} from '@heroicons/react/24/outline'

import VisionUploadPictureButton from '../button/vision-upload-picture'
import {ProductItemSkeleton} from './shopify-product-item'
import {ShopifyProductList} from './shopify-result-list'

interface ProductSearchComponentProps {
  inputImage: string
  visionAnalysisResult: VisionAnalysisResult
}

export default function ProductSearchComponent({
  inputImage,
  visionAnalysisResult,
}: ProductSearchComponentProps) {
  const router = useRouter()
  const {id: searchId} = useParams<{id: string}>()
  const user = useAuthUser()
  const isGuest = useIsGuest()
  const {data: userNotification} = useUserNotificationsBySearchId({
    searchId: searchId,
  })

  const sendNotificationRequestMutation = useUserNotificationRequestsMutation()

  const [showNotifyMe, setShowNotifyMe] = useState(
    visionAnalysisResult.results.length == 0 &&
      visionAnalysisResult.emptyResults.length != 0,
  )
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number | null>(0)
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  })
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    const handleImageLoad = () => {
      if (imageRef.current) {
        let {width, height} = imageRef.current.getBoundingClientRect()
        width += 8 // Add 2 pixels to account for border
        height += 8 // Add 2 pixels to account for border
        setImageDimensions({width, height})
      }
    }

    if (imageRef.current) {
      imageRef.current.addEventListener('load', handleImageLoad)
    }

    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleImageLoad)
      }
    }
  }, [inputImage])

  const selectedGroup = useMemo(() => {
    if (selectedGroupIndex === null) return null
    return visionAnalysisResult.results[selectedGroupIndex] ?? null
  }, [selectedGroupIndex, visionAnalysisResult.results])

  return (
    <div className="no-scrollbar absolute top-10 flex size-full flex-col items-center overflow-y-auto bg-white py-2 pb-10 lg:flex-row lg:gap-8">
      <div className="flex w-full flex-col items-center lg:w-fit">
        <div
          className={cx(
            'flex h-72 w-80 flex-row items-center justify-center',
            'lg:h-80 lg:w-[450px]',
            'xl:h-[420px]',
            '2xl:h-[500px] 2xl:w-[560px]',
          )}
        >
          <div className="flex size-full items-center justify-center">
            <div className="relative flex size-full items-center justify-center">
              <AnalyzingOverlay
                className="absolute z-10"
                hideAnalyzing={selectedGroup == null}
                boundingPoly={selectedGroup?.boundingPoly}
                style={{
                  width: `${imageDimensions.width}px`,
                  height: `${imageDimensions.height}px`,
                }}
              />
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                ref={imageRef}
                className="relative max-h-full max-w-full object-contain"
                alt="placeholder"
                src={inputImage}
                srcSet={`
                ${inputImage}?w=128 128w,
                ${inputImage}?w=256 256w,
                ${inputImage}?w=384 384w,
                ${inputImage}?w=512 512w,
                ${inputImage}?w=768 768w,
                ${inputImage}?w=1024 1024w,
              `}
                sizes="70vw"
              />
            </div>
          </div>

          <div className="flex h-full flex-col justify-center gap-2">
            {visionAnalysisResult.results.map((group, index) => (
              <Button
                variant="ghost"
                key={index + group.objectName}
                className={`border-1 ml-6 aspect-square w-12 shrink overflow-hidden rounded-lg bg-black/20 p-0 ring ${
                  selectedGroupIndex === index
                    ? 'ring-zinc-800'
                    : 'opacity-50 ring-transparent'
                }`}
                onPress={() => {
                  setSelectedGroupIndex(index)
                }}
              >
                <CroppedImage
                  imageUrl={inputImage}
                  boundingPoly={group.boundingPoly}
                  className="size-full rounded"
                />
              </Button>
            ))}
            {visionAnalysisResult.emptyResults.length != 0 &&
              visionAnalysisResult.results.length != 0 && (
                <Button
                  variant="ghost"
                  className="ml-6 aspect-square w-12"
                  onPress={() => setShowNotifyMe(!showNotifyMe)}
                >
                  <EllipsisHorizontalIcon className="size-6" />
                </Button>
              )}
          </div>
        </div>
        {(visionAnalysisResult == null ||
          visionAnalysisResult.results.length == 0 ||
          showNotifyMe) && (
          <div className="avenir height-grow-300 relative mt-8 flex w-full flex-col items-center justify-center bg-stone-100 p-4 text-center">
            {!showNotifyMe ? (
              <div className="">
                <p className="text-base">
                  No products found in image. Try again.
                </p>
                <VisionUploadPictureButton className="avenir fadein-300 mt-2">
                  Upload image
                </VisionUploadPictureButton>
              </div>
            ) : (
              <div className="">
                <p className="text-base">
                  No matches for
                  {visionAnalysisResult.emptyResults.map(
                    (emptyResult, index) => (
                      <span
                        key={index}
                        className="font-semibold"
                      >{` ${emptyResult.objectName}${index == visionAnalysisResult.emptyResults.length - 1 ? '' : ','}`}</span>
                    ),
                  )}
                  <br /> We&apos;ll source them for you.
                </p>
                {!userNotification ? (
                  <Button
                    className="avenir relative mt-2 shrink-0 items-center justify-center gap-2"
                    variant="primary"
                    isPending={sendNotificationRequestMutation.isPending}
                    onPress={() => {
                      if (isGuest) {
                        const searchParams = new URLSearchParams()
                        searchParams.set(
                          'redirectUrl',
                          window.location.toString(),
                        )
                        searchParams.set('notificationId', searchId)

                        router.push(
                          `${process.env.NEXT_PUBLIC_MAIN_STORE_URL}/login?${searchParams.toString()}`,
                        )
                        return
                      }

                      // Create user notification
                      sendNotificationRequestMutation.mutate({
                        searchId: searchId,
                      })
                    }}
                  >
                    Get notified
                  </Button>
                ) : (
                  <Text>
                    You&apos;ll be notified when we find matches for your image.
                  </Text>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {selectedGroup != null && (
        <>
          <div className="mt-6 flex w-full flex-col items-center px-4 lg:mt-0 lg:h-full">
            <div className="size-full">
              <ShopifyProductList
                title={`${selectedGroup?.objectName} results`}
                products={selectedGroup?.products ?? []} // Use products for the selected group
                imageIndices={selectedGroup?.imageIndices ?? []} // Use image indices for the selected group
                variantIds={selectedGroup?.variantIds ?? []} // Use variant IDs for the selected group
                category={selectedGroup?.objectName || ''}
                renderEnd={() => (
                  <div className="mt-5 flex w-full justify-center">
                    <Button
                      className="avenir relative flex w-52 shrink-0 items-center justify-center gap-2 text-lg"
                      variant="primary"
                      onPress={() => {
                        const shareUrl =
                          window.location.origin + window.location.pathname
                        if (navigator.share) {
                          navigator
                            .share({
                              title: 'The Same Same',
                              url: shareUrl,
                            })
                            .catch((err) => console.error(err))
                        } else {
                          console.error(
                            'Web Share API is not supported in your browser.',
                          )
                        }
                      }}
                    >
                      Share results
                    </Button>
                  </div>
                )}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export function ProductSearchComponentSkeleton() {
  const [groupSize, setGroupSize] = useState(2) // State to hold dynamic group size
  const [width] = useWindowSize() // Hook to get the current window width

  useEffect(() => {
    if (width && width >= 1536) {
      setGroupSize(4)
    } else if (width && width >= 1280) {
      setGroupSize(4)
    } else if (width && width >= 640) {
      setGroupSize(3)
    } else {
      setGroupSize(2)
    }
  }, [width])
  return (
    <div className="no-scrollbar absolute top-8 flex size-full flex-col items-center overflow-y-auto bg-white p-4 pb-10 lg:flex-row lg:gap-8">
      <div className="flex h-72 w-80 flex-row items-center justify-center gap-4 lg:h-80 lg:w-[450px] xl:h-[420px] xl:w-[600px]">
        <div className="flex size-full items-center justify-center">
          <div className="relative flex size-full items-center justify-center gap-4">
            <div className="relative size-96 max-h-full max-w-full scale-[0.98] rounded-lg bg-black/20 object-contain" />
            <div className="flex flex-col gap-4">
              {Array.from({length: 4}).map((_, index) => (
                <div
                  key={index}
                  className="border-1 aspect-square w-12 shrink overflow-hidden rounded-lg bg-black/20 p-0 opacity-50 ring ring-transparent"
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex w-full flex-col items-center lg:mt-0 lg:h-full">
        <div className="size-full">
          <VirtualList
            isLoading
            placeholderItemsCount={20}
            items={[]}
            renderItem={() => null}
            overscan={5}
            count={0}
            renderPlaceholderItem={() => {
              return (
                <div className="mb-4 flex justify-center gap-4">
                  {Array.from({length: groupSize}).map((_, idx) => (
                    <ProductItemSkeleton
                      key={idx}
                      className="relative w-40 xl:w-60"
                    />
                  ))}
                </div>
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}
