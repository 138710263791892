import { useEffect, useRef } from "react";

import { BoundingPoly } from "@atorie/api/vision";

export type CroppedImageProps = {
  imageUrl: string;
  boundingPoly: BoundingPoly;
  className?: string; // Optional class names for styling
};

export function CroppedImage({
  imageUrl,
  boundingPoly,
  className,
}: CroppedImageProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (!canvas || !context) return;

    const img = new Image();
    img.src = imageUrl + "?w=128";

    img.onload = () => {
      const imageWidth = img.width;
      const imageHeight = img.height;

      // Convert normalized vertices to actual pixel values
      const xMin =
        Math.min(...boundingPoly.normalizedVertices!.map((v) => v.x!)) *
        imageWidth;
      const yMin =
        Math.min(...boundingPoly.normalizedVertices!.map((v) => v.y!)) *
        imageHeight;
      const xMax =
        Math.max(...boundingPoly.normalizedVertices!.map((v) => v.x!)) *
        imageWidth;
      const yMax =
        Math.max(...boundingPoly.normalizedVertices!.map((v) => v.y!)) *
        imageHeight;

      const cropWidth = xMax - xMin;
      const cropHeight = yMax - yMin;

      // Calculate aspect ratio
      const aspectRatio = cropWidth / cropHeight;

      // Set the canvas size based on parent container size (with className)
      const parentElement = canvas.parentElement;
      if (parentElement) {
        const computedStyle = window.getComputedStyle(parentElement);
        const parentWidth = parseFloat(computedStyle.width);
        const parentHeight = parseFloat(computedStyle.height);

        // Maintain aspect ratio of the cropped image within the parent container
        if (parentWidth / aspectRatio <= parentHeight) {
          canvas.width = parentWidth;
          canvas.height = parentWidth / aspectRatio;
        } else {
          canvas.width = parentHeight * aspectRatio;
          canvas.height = parentHeight;
        }

        // Draw the cropped area onto the canvas, scaled to fit the container
        context.drawImage(
          img,
          xMin,
          yMin,
          cropWidth,
          cropHeight,
          0,
          0,
          canvas.width,
          canvas.height
        );
      }
    };
  }, [imageUrl, boundingPoly]);

  return <canvas ref={canvasRef} />;
}

export default CroppedImage;
