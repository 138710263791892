interface LoaderProps {
  src: string
  width: number
  quality?: number
}

export default function cloudfrontLoader({src, width, quality}: LoaderProps) {
  if (src.includes('cdn.shopify')) {
    return `${src}&width=${width}`
  }
  return `${src}?w=${width}&q=${quality || 75}`
}
