'use client'

import useEmblaCarousel from 'embla-carousel-react'
import Image from 'next/image'
import {useRouter} from 'next/navigation'
import {useCallback, useEffect, useMemo, useState} from 'react'

import {analyticsService, personalizeService} from '@atorie/api/analytics'
import {StorefrontGenerated, StorefrontTypes} from '@atorie/api/types'
import {cva, VariantProps} from '@atorie/core/cva'
import {useAuthUser, useWindowSize} from '@atorie/hooks'
import {Button} from '@atorie/ui/buttons'
import {Spinner} from '@atorie/ui/feedback'
import {Text} from '@atorie/ui/typography'

export interface ProductItemProps
  extends VariantProps<typeof productItemVariants> {
  product: StorefrontGenerated.ProductCardFragment
  imageIndex?: number
  className?: string
}

const productItemVariants = cva({})

export default function ProductMatchView({
  product,
  imageIndex = 0,
  className,
}: ProductItemProps) {
  const router = useRouter()
  const [enlargeImage, setEnlargeImage] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [width] = useWindowSize()
  const {user} = useAuthUser()
  const extractSizesAndColors = (
    variants?: StorefrontTypes.ProductVariant[] | null,
  ): {sizes: string[]; colors: string[]} => {
    const sizes: Set<string> = new Set()
    const colors: Set<string> = new Set()

    if (variants) {
      variants.forEach((variant) => {
        variant.selectedOptions.forEach((option) => {
          if (option.name.toLowerCase() === 'size') {
            sizes.add(option.value.split('/')[0].replace(' ', ''))
          } else if (option.name.toLowerCase() === 'color') {
            colors.add(option.value)
          }
        })
      })
    }

    return {
      sizes: Array.from(sizes),
      colors: Array.from(colors),
    }
  }

  const productSizesAndColors = useMemo(() => {
    return extractSizesAndColors(
      product?.variants?.nodes as StorefrontTypes.ProductVariant[],
    )
  }, [product?.variants?.nodes])

  if (!product) return null

  // Get product images
  const images =
    product.images?.edges?.map((edge) => edge.node.url as string) ?? []

  // Get the first variant's image URL and alt text
  const firstVariant =
    product?.variants?.nodes?.[0] || (product?.variants as any)?.edges?.[0].node
  const imageUrl = images[imageIndex] || ''
  const imageAltText = firstVariant?.image?.altText || 'Product image'

  const firstVariantId = firstVariant?.id.split('/').pop()

  // Get the price from the first variant or price range
  const price: string = firstVariant?.price?.amount ?? ''
  const originalPrice: string = firstVariant?.compareAtPrice?.amount ?? ''
  const currencyCode = firstVariant?.price?.currencyCode ?? ''

  // Calculate discount percentage
  const discountPercentage = originalPrice
    ? Math.round(
        ((parseFloat(originalPrice) - parseFloat(price)) /
          parseFloat(originalPrice)) *
          100,
      )
    : null

  const handleShopNow = () => {
    if (firstVariantId) {
      setIsRedirecting(true)
      try {
        analyticsService.recordEvent({
          name: 'PRODUCT_SHOP_NOW',
          attributes: {
            productId: product.id,
            userId: user?.id ?? '',
            origin: 'the-same-same',
          },
        })
        personalizeService.recordEvent({
          eventType: 'PRODUCT_SHOP_NOW',
          userId: user?.id ?? '',
          properties: {
            itemId: firstVariantId,
          },
        })
      } catch (error) {
        console.error('Error recording event:', error)
      }

      const productUrl = `https://panki.shop/products/${product.handle}`

      setTimeout(() => {
        if (width && width < 768) {
          router.push(productUrl)
        } else {
          window.open(productUrl, '_blank')
          setIsRedirecting(false)
        }
      }, 300)
    }
  }

  return (
    <div className="relative flex h-full w-[354px] flex-col items-center overflow-hidden rounded-lg bg-white text-start shadow-sm sm:w-[600px] xl:w-[1000px] xl:flex-row xl:gap-4">
      <div
        className={`absolute top-0 z-20 flex size-full flex-col items-center justify-center gap-2 bg-white text-center transition-[left] ${isRedirecting ? 'left-0' : 'left-full'}`}
      >
        <Text className="mb-2 text-xl font-medium ">
          Taking you to <span className="font-bold">panki.shop</span>
        </Text>
        <Spinner color="black" />
      </div>
      {/* Scrollable Image Section */}
      <div
        className={`mb-4 w-full shrink-0 bg-stone-100 transition-all ${enlargeImage ? ' absolute h-[92%] xl:h-[92%] xl:w-[calc(100%-24px)]' : 'relative h-72 sm:h-[440px] xl:h-[92%] xl:w-[650px]'}`}
        onClick={() => setEnlargeImage(!enlargeImage)}
      >
        <ImagesScroll
          images={images}
          isEnlarged={enlargeImage}
          product={product}
        />
      </div>

      {/* Price Section */}
      <div className="flex size-full flex-col px-3 xl:h-auto xl:shrink xl:gap-2 xl:self-center">
        <div className="my-2 flex w-full items-start justify-between gap-2 self-start text-lg sm:text-xl xl:flex-col-reverse">
          <div className="flex flex-col self-start capitalize xl:gap-2">
            <div className="font-medium">
              {product?.vendor.toLowerCase() || ''}{' '}
            </div>
            <div className="h-7 overflow-hidden">
              {product?.title || 'Product Title'}
            </div>
          </div>
          <div className="font-bold text-gray-800 ">
            {currencyCode === StorefrontTypes.CurrencyCode.Usd &&
              `$${price.split('.')[0]}`}
          </div>
        </div>
        <Text className="self-start text-xs text-gray-500 sm:text-sm">
          {`${productSizesAndColors.sizes.length > 1 ? `${productSizesAndColors.sizes.join(', ')} available` : 'Available'}`}
          {`${productSizesAndColors.colors.length > 1 ? ` in ${productSizesAndColors.colors.length} colorways` : ``}`}
        </Text>

        {/* Buttons */}
        <Button
          variant="primary"
          onPress={handleShopNow}
          className="avenir mt-4 self-center"
        >
          Shop now
        </Button>
      </div>
    </div>
  )
}
function ImagesScroll({
  images,
  isEnlarged,
  product,
}: {
  images: string[]
  isEnlarged?: boolean
  product: StorefrontGenerated.ProductCardFragment
}) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [emblaRef, emblaApi] = useEmblaCarousel()
  const {user} = useAuthUser()
  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [emblaApi, images, product, selectedIndex, user])

  useEffect(() => {
    try {
      const firstVariant =
        product?.variants?.nodes?.[0] ||
        (product?.variants as any)?.edges?.[0].node
      console.log('Recording event')
      analyticsService.recordEvent({
        name: 'PRODUCT_IMAGE_VIEWED',
        attributes: {
          userId: user?.id ?? '',
          productId: product.id,
          origin: 'the-same-same',
        },
      })
      personalizeService.recordEvent({
        eventType: 'PRODUCT_IMAGE_VIEWED',
        userId: user?.id ?? '',
        properties: {
          itemId: firstVariant.id,
        },
      })
    } catch (error) {
      console.error('Error recording event:', error)
    }
  }, [selectedIndex])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  return (
    <div className="relative size-full">
      <div className="size-full overflow-hidden" ref={emblaRef}>
        <div className="flex h-full">
          {images.map((src, idx) => (
            <ProductImage
              key={src + idx}
              src={src}
              index={idx}
              selectedIndex={selectedIndex}
            />
          ))}
        </div>
      </div>
      <div className="absolute inset-x-0 -bottom-4">
        <DotButton selectedIndex={selectedIndex} slides={images} />
      </div>
    </div>
  )
}

function ProductImage({
  src,
  index,
  selectedIndex,
}: {
  src: string
  index: number
  selectedIndex: number
}) {
  const loading = Math.abs(selectedIndex - index) < 3 ? 'eager' : 'lazy'
  return (
    <div className="min-w-0 shrink-0 grow-0 basis-full">
      <div className="flex size-full justify-center overflow-hidden">
        <Image
          src={src}
          className="max-h-full w-fit object-contain"
          alt=""
          width={354}
          height={0}
          loading={loading}
        />
      </div>
    </div>
  )
}

function DotButton({
  selectedIndex,
  slides,
}: {
  selectedIndex: number
  slides: string[]
}) {
  const maxDots = 4
  const totalSlides = slides.length

  // Function to determine dot size based on its index relative to the selected index
  const getDotSize = (idx: number) => {
    const distanceFromCenter = Math.abs(selectedIndex - idx)
    // As the distance increases, the size decreases (range between 4px and 12px)
    const size = Math.max(3, 6 - distanceFromCenter)
    return `${size}px`
  }

  const dot = (key, selected, size) => (
    <div
      key={key}
      className={`rounded-full transition-colors duration-300 ${selected ? 'bg-zinc-800' : 'bg-zinc-800/60'}`}
      style={{width: size, height: size}}
    />
  )

  const renderDots = () => {
    const dots: React.JSX.Element[] = []

    // Always render the first dot
    dots.push(dot(0, selectedIndex === 0, getDotSize(0)))

    // Dynamically render the middle three dots based on the selected index
    const start = Math.max(
      1,
      Math.min(selectedIndex - 1, totalSlides - maxDots),
    )
    const end = Math.min(start + 2, totalSlides - 2)

    for (let i = start; i <= end; i++) {
      dots.push(dot(i, selectedIndex === i, getDotSize(i)))
    }

    if (totalSlides == 1) return dots
    // Always render the last dot
    dots.push(
      dot(
        totalSlides - 1,
        selectedIndex === totalSlides - 1,
        getDotSize(totalSlides - 1),
      ),
    )

    return dots
  }

  return (
    <div className="flex items-center justify-center gap-1.5">
      {renderDots()}
    </div>
  )
}
