import * as React from "react";
import gsap from "gsap";

interface CircularImageIndexIndicatorProps {
  selectedIndex: number;
  totalImages: number;
  className?: string;
  onClick?: (index: number) => void;
  maxDots?: number;
  maxDotSize?: number;
  minDotSize?: number;
}

export function CircularImageIndexIndicator({
  selectedIndex,
  totalImages,
  className = "",
  maxDots = 4,
  maxDotSize = 8,
  minDotSize = 5,
  onClick,
}: CircularImageIndexIndicatorProps) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const dotsRef = React.useRef<HTMLDivElement[]>([]);
  const timeline = React.useRef<gsap.core.Timeline | null>(null);

  const getDotSize = React.useCallback(
    (idx: number) => {
      const distanceFromCenter = Math.abs(selectedIndex - idx);
      const size = Math.max(minDotSize, maxDotSize - distanceFromCenter * 1.2);
      return size;
    },
    [selectedIndex, maxDotSize, minDotSize]
  );

  React.useEffect(() => {
    if (timeline.current) {
      timeline.current.kill();
    }

    const ctx = gsap.context(() => {
      dotsRef.current.forEach((dot, idx) => {
        if (dot) {
          const size = getDotSize(idx);
          const isSelected = selectedIndex === idx;

          gsap.to(dot, {
            width: size,
            height: size,
            scale: isSelected ? 1.1 : 1,
            backgroundColor: isSelected ? "#292524" : "transparent",
            borderColor: "#292524",
            duration: 0.3,
            ease: "power3.out",
            overwrite: true,
          });
        }
      });
    });

    return () => ctx.revert();
  }, [selectedIndex, getDotSize]);

  const setDotRef = React.useCallback(
    (el: HTMLDivElement | null, index: number) => {
      if (el) {
        dotsRef.current[index] = el;
      }
    },
    []
  );

  const createDot = React.useCallback(
    (index: number) => (
      <div
        key={index}
        ref={(el) => setDotRef(el, index)}
        className="rounded-full border border-stone-800 cursor-pointer"
        style={{
          width: getDotSize(index),
          height: getDotSize(index),
        }}
        onClick={() => onClick?.(index)}
        aria-label={`Image ${index + 1} of ${totalImages}`}
        role="button"
      />
    ),
    [totalImages, getDotSize, onClick, setDotRef]
  );

  const dots = React.useMemo(() => {
    if (totalImages <= 1) return [createDot(0)];

    const result: React.ReactNode[] = [];
    result.push(createDot(0));

    const start = Math.max(
      1,
      Math.min(selectedIndex - 1, totalImages - maxDots)
    );
    const end = Math.min(start + maxDots - 2, totalImages - 2);

    for (let i = start; i <= end; i++) {
      result.push(createDot(i));
    }

    if (totalImages > 1) {
      result.push(createDot(totalImages - 1));
    }

    return result;
  }, [totalImages, createDot, selectedIndex, maxDots]);

  return (
    <div
      className={`flex items-center justify-center gap-1.5 ${className}`}
      role="group"
      aria-label="Image navigation"
    >
      <div
        ref={containerRef}
        className="w-16 h-4 flex items-center justify-center gap-1 backdrop-blur-sm bg-white/30 rounded-full"
      >
        {dots}
      </div>
    </div>
  );
}
