'use client'

import Image from 'next/image'
import {useRef} from 'react'

import {StorefrontGenerated, StorefrontTypes} from '@atorie/api/types'
import {cva, cx, VariantProps} from '@atorie/core/cva'
import {useBoolean, useShopifyProductByIdQuery} from '@atorie/hooks'
import {Spinner} from '@atorie/ui/feedback'
import {Text} from '@atorie/ui/typography'

export interface ProductItemProps
  extends VariantProps<typeof productItemVariants> {
  product?: StorefrontGenerated.ProductCardFragment | null
  productId?: string | null
  imageIndex?: number
  className?: string
  id?: string
  query_id?: string
  hideSource?: boolean
  hidePrice?: boolean
  hideState?: boolean
}

const ADD_SOURCE_WIDTH_THRESHOLD = 80
const productItemVariants = cva({})

export function ProductItem({
  product,
  productId,
  imageIndex = 0,
  className,
  hidePrice = true,
}: ProductItemProps) {
  const [shouldAddMoreInfo, setShouldAddMoreInfo] = useBoolean(false)
  const productElementRef = useRef<HTMLDivElement>(null)

  // Query product by productId if product is null
  const {data: fetchedProduct, isLoading} = useShopifyProductByIdQuery(
    productId ?? '',
    {
      enabled: !product && productId != null, // Only run if product is null and productId is available
    },
  )
  const productData = product || fetchedProduct

  const firstVariant =
    productData?.variants?.nodes?.[0] ||
    (productData?.variants as any)?.edges?.[0].node
  const images = productData?.images?.edges ?? []
  const imageUrl =
    imageIndex < images?.length
      ? images[imageIndex].node.url
      : firstVariant?.image?.url || ''
  const imageAltText = firstVariant?.image?.altText || 'Product image'

  const price: string = firstVariant?.price?.amount || ''
  const currencyCode = firstVariant?.price?.currencyCode || ''

  return (
    <div
      className={cx(
        'relative aspect-[5/6] w-20 shrink-0 overflow-hidden rounded-md bg-black/20',
        className,
      )}
      ref={productElementRef}
    >
      {isLoading && !productData ? (
        <div className="flex size-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="relative size-full">
          <Image
            src={imageUrl}
            className={`size-full object-cover ${productData ? 'opacity-100' : 'opacity-0'}`}
            alt={imageAltText}
            width={80}
            height={96}
            sizes="(max-width: 768px) 40vw, 15vw"
          />
        </div>
      )}
      {!hidePrice && (
        <div className="absolute bottom-0 z-20 flex w-full flex-col bg-gradient-to-b from-transparent to-black/50 px-2 pb-1 pt-6 text-start">
          {price &&
            currencyCode == StorefrontTypes.CurrencyCode.Usd &&
            `$${price.split('.')[0]}`}
          <Text
            className={`-my-px line-clamp-1 text-clip font-bold text-white ${shouldAddMoreInfo ? 'text-[12px]' : 'text-[10px]'}`}
          >
            {productData?.vendor}
          </Text>
        </div>
      )}
    </div>
  )
}

interface ProductItemSkeletonProps {
  className?: string
}

export function ProductItemSkeleton({className}: ProductItemSkeletonProps) {
  return (
    <div
      className={cx(
        'relative aspect-[5/6] w-full shrink-0 animate-pulse overflow-hidden rounded-md bg-neutral-800/10',
        className,
      )}
    />
  )
}

export default ProductItem
