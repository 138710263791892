import {BoundingPoly} from '@atorie/api/vision'

export interface DotInPolyOverlayProps {
  boundingPoly?: BoundingPoly | null
}

export function DotInPolyOverlay({
  boundingPoly,
}: DotInPolyOverlayProps) {
  if (
    !boundingPoly ||
    !boundingPoly.normalizedVertices ||
    boundingPoly.normalizedVertices.length < 4
  ) {
    return null // Return null if there's no valid boundingPoly or if it's not properly defined
  }

  // Calculate the center of the bounding polygon
  const centerX =
    (boundingPoly.normalizedVertices[0].x! +
      boundingPoly.normalizedVertices[2].x!) /
    2
  const centerY =
    (boundingPoly.normalizedVertices[0].y! +
      boundingPoly.normalizedVertices[2].y!) /
    2

  return (
    <div
      className="absolute rounded-full border border-zinc-800 bg-white shadow-[0px_0px_4px_2px_rgba(255,255,255,0.2)] transition-all"
      style={{
        top: `calc(${centerY * 100}% - 10px)`, // Center the circle vertically
        left: `calc(${centerX * 100}% - 10px)`, // Center the circle horizontally
        width: '7px', // Fixed width for the circle
        height: '7px', // Fixed height for the circle
      }}
    />
  )
}
