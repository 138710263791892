'use client'

import Image from 'next/image'

import {cx} from '@atorie/core/cva'

import {Text} from '../typography/text'

export interface ImageItemProps {
  image: {
    src: string
    isSelected: boolean
  }
  className?: string
}

export function ImageItem({image, className}: ImageItemProps) {
  return (
    <div
      className={cx(
        'fadein-150 relative aspect-[5/6] w-20 shrink-0 overflow-hidden rounded-md',
        className,
      )}
    >
      <div className={`relative size-full `}>
        <div className="absolute inset-0 size-full">
          <Image
            src=""
            overrideSrc={image.src ?? ''}
            className="size-full object-cover transition-opacity"
            alt=""
            fill
            sizes="25vw"
            priority
          />
        </div>
        {image.isSelected && (
          <div className="absolute inset-0 flex size-full items-center justify-center bg-neutral-800/50">
            <Text font="anton" className="text-lg font-bold text-white">
              SELECTED
            </Text>
          </div>
        )}
      </div>
    </div>
  )
}

interface ImageItemSkeletonProps {
  className?: string
}

export function ImageItemSkeleton({className}: ImageItemSkeletonProps) {
  return (
    <div
      className={cx(
        'relative aspect-[5/6] w-full shrink-0 animate-pulse overflow-hidden rounded-md bg-neutral-800/10',
        className,
      )}
    ></div>
  )
}

export default ImageItem
