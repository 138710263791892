import * as React from "react";

interface ImageIndexIndicatorProps {
  selectedIndex: number;
  totalImages: number;
  className?: string;
  /**
   * Callback when a bar is clicked
   */
  onClick?: (index: number) => void;
  /**
   * Maximum bar width in pixels
   * @default 24
   */
  maxBarWidth?: number;
  /**
   * Minimum bar width in pixels
   * @default 12
   */
  minBarWidth?: number;
  /**
   * Bar height in pixels
   * @default 2
   */
  barHeight?: number;
}

export function ImageIndexIndicator({
  selectedIndex,
  totalImages,
  className = "",
  maxBarWidth = 120,
  minBarWidth = 4,
  barHeight = 3,
  onClick,
}: ImageIndexIndicatorProps) {
  const getFlexGrow = React.useCallback(
    (idx: number) => {
      const distanceFromCenter = Math.abs(selectedIndex - idx);
      // Same width for selected and adjacent bars (distance 0 or 1)
      if (distanceFromCenter >= 1) {
        return 2.5;
      }
      // Smaller width for bars further away
      return 3;
    },
    [selectedIndex]
  );

  // Update createBar to use dynamic flex-grow
  const createBar = React.useCallback(
    (index: number) => (
      <div
        key={index}
        className={`transition-all duration-300 rounded-full ${
          selectedIndex === index ? "bg-white" : "bg-gray-500/50"
        } cursor-pointer`}
        style={{
          height: `${barHeight}px`,
          borderRadius: `99px`,
          minWidth: `${minBarWidth}px`,
          maxWidth: `${maxBarWidth}px`,
          flexGrow: getFlexGrow(index),
        }}
        onClick={() => onClick?.(index)}
        aria-label={`Image ${index + 1} of ${totalImages}`}
        role="button"
      />
    ),
    [
      selectedIndex,
      totalImages,
      barHeight,
      onClick,
      minBarWidth,
      maxBarWidth,
      getFlexGrow,
    ]
  );

  // Simplified bars calculation to show all bars
  const bars = React.useMemo(() => {
    if (totalImages <= 1) return [createBar(0)];

    return Array.from({ length: totalImages }, (_, index) => createBar(index));
  }, [totalImages, createBar]);

  return (
    <div
      className={`flex items-center justify-between w-full gap-1 ${className}`}
      role="group"
      aria-label="Image navigation"
    >
      {bars}
    </div>
  );
}
