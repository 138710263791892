import Image from 'next/image'

import {Text} from '@atorie/ui/typography'
import {AnalyzingOverlay} from '@atorie/ui/vision'

import {Logo} from '../Logo'

interface ImagesUploadingProps {
  images: string[]
  count: number
  progress: number
}

export default function VisionUploading({
  images,
  count,
  progress,
}: ImagesUploadingProps) {
  return (
    <div className="flex h-svh flex-col items-center justify-center overflow-hidden px-6">
      <Logo className="absolute top-2" />
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <div className="relative">
        <Image
          className="mt-4 max-h-[75svh] rounded-md object-contain drop-shadow-xl"
          alt="placeholder"
          src={images[0] ?? ''}
          width={500}
          height={700}
          sizes="75vw"
        />
        <div className="absolute inset-[-4px] top-0 pt-3">
          <AnalyzingOverlay />
        </div>
      </div>
      <div className="avenir my-6 flex flex-col">
        <Text className="text-center text-2xl text-black">Searching...</Text>
      </div>
    </div>
  )
}

function imagePlural(count: number) {
  return count > 1 ? 'images' : 'image'
}
