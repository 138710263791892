import {BoundingPoly} from '@atorie/api/vision'

export interface BoundingPolyOverlayProps {
  boundingPoly?: BoundingPoly | null
}

export function BoundingPolyOverlay({
  boundingPoly,
}: BoundingPolyOverlayProps) {
  if (
    !boundingPoly ||
    !boundingPoly.normalizedVertices ||
    boundingPoly.normalizedVertices.length < 4
  ) {
    return null // Return null if there's no valid boundingPoly or if it's not properly defined
  }

  return (
    <div
      className="absolute rounded-lg bg-white/60 transition-all"
      style={{
        top: `${boundingPoly.normalizedVertices[0].y! * 100}%`,
        left: `${boundingPoly.normalizedVertices[0].x! * 100}%`,
        width: `calc(${boundingPoly.normalizedVertices[1].x! * 100}% - ${boundingPoly.normalizedVertices[0].x! * 100}%)`,
        height: `calc(${boundingPoly.normalizedVertices[2].y! * 100}% - ${boundingPoly.normalizedVertices[0].y! * 100}%)`,
      }}
    />
  )
}
