'use client'

import {useRouter} from 'next/navigation'
import React, {forwardRef, useMemo, useState} from 'react'
import toast from 'react-hot-toast'
import {MdImageSearch} from 'react-icons/md'

import {analyticsService} from '@atorie/api/analytics'
import {VisionAnalysisResult} from '@atorie/api/vision'
import {cx} from '@atorie/core/cva'
import {
  useAuthUser,
  useVisionProductSearchMutation,
  useVisionSearchEntryMutation,
} from '@atorie/hooks'
import {Button} from '@atorie/ui/buttons'
import {Dialog, FileTrigger, Modal} from '@atorie/ui/rac'
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline'

import ProductSearchComponent from '../shopify/shopify-vision-search-result'
import VisionUploading from '../uploading/vision-uploading'

interface UploadPictureButtonProps {
  className?: string
  children?: React.ReactNode
  isMinimized?: boolean
}

export const VisionUploadPictureButton = forwardRef<
  HTMLInputElement,
  UploadPictureButtonProps
>(({className, children, isMinimized}, ref) => {
  const router = useRouter()
  const [files, setFiles] = useState<FileList | null>(null)
  const [productSearchResults, setProductSearchResults] =
    useState<VisionAnalysisResult | null>(null)
  const [inputImage, setInputImage] = useState<string | null>(null)
  const visionProductSearchMutation = useVisionProductSearchMutation()
  const [openModal, setOpenModal] = useState(false)
  const visionSearchEntries = useVisionSearchEntryMutation()
  const {user} = useAuthUser()

  const previewImages = useMemo(() => {
    if (!files) return []
    return Array.from(files).map((file) => URL.createObjectURL(file))
  }, [files])

  function handleUpload(fileList: FileList | null) {
    if (!fileList || fileList.length === 0) return

    const file = fileList[0]
    console.log('File details:', {
      name: file.name,
      size: file.size,
      type: file.type,
    })

    const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp']
    if (!validTypes.includes(file.type)) {
      toast.error(
        'Invalid file type. Please upload a PNG, JPEG, or WebP image.',
      )
      return
    }

    setOpenModal(true)
    setFiles(fileList)
    visionProductSearchMutation.mutate(
      {
        imageUrl: fileList[0],
      },
      {
        onSuccess: (data) => {
          visionSearchEntries.mutate(
            {
              imageUrl: data.url,
              userId: user?.id ?? '',
              result: data.result,
            },
            {
              onSuccess: (gqlData) => {
                try {
                  analyticsService.recordEvent({
                    name: 'VISION_SEARCH_ENTRY_CREATED',
                    attributes: {
                      userId: user?.id ?? '',
                      origin: 'the-same-same',
                    },
                  })
                } catch (error) {
                  console.error('Error recording event:', error)
                }
                if (
                  data.result.results.length === 0 &&
                  data.result.emptyResults.length === 0
                ) {
                  router.push(`/search/${gqlData.id}`)
                  return
                } else {
                  setProductSearchResults(data.result)
                  setInputImage(data.url)
                  toast.success('Product analyzed successfully')
                  window.history.pushState({}, '', `/search/${gqlData.id}`)
                }
              },
              onError: (error) => {
                console.error('Search Entry Error:', error)
                toast.error('Failed to save search results')
                setOpenModal(false)
              },
            },
          )
        },
        onError: (error) => {
          console.error('Vision API Error:', error)
          toast.error(
            'An error occurred while analyzing the picture. Please try again.',
          )
          setTimeout(() => setOpenModal(false), 2000)
        },
      },
    )
  }

  useMemo(() => {
    return () => {
      previewImages?.forEach((url) => URL.revokeObjectURL(url ?? ''))
    }
  }, [previewImages])

  return (
    <div
      className={cx(
        className,
        'fadein-500 bottom-4 flex w-full justify-center transition-all duration-300 ease-in-out',
      )}
    >
      <Modal isOpen={openModal} className="isolate">
        <Dialog className="bg-white">
          {productSearchResults && inputImage ? (
            <ProductSearchComponent
              inputImage={inputImage}
              visionAnalysisResult={productSearchResults}
            />
          ) : (
            <VisionUploading images={previewImages} count={1} progress={0} />
          )}
        </Dialog>
      </Modal>
      <FileTrigger
        acceptedFileTypes={[
          'image/png',
          'image/jpeg',
          'image/jpg',
          'image/webp',
        ]}
        allowsMultiple={false}
        onSelect={handleUpload}
        ref={ref}
      >
        <Button
          className={cx(
            'text-md flex items-center justify-center gap-2 pl-2 transition-all duration-300 ease-in-out',
            className,
          )}
          onPress={() => {
            if (ref && 'current' in ref && ref.current) {
              ref.current.click()
            }
          }}
          // variant="primary"
        >
          <MagnifyingGlassIcon
            strokeWidth={2}
            className={`m-1 mb-1.5 size-6 text-zinc-800 ${
              !isMinimized ? 'opacity-100' : 'opacity-0'
            }`}
          />
          <span
            className={`line-clamp-1 max-w-full shrink grow-0 overflow-hidden text-clip transition-opacity duration-300 ${
              !isMinimized ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {children}
          </span>
          <MdImageSearch
            size={36}
            className={`absolute m-1 mb-1.5 ml-2.5 size-full shrink-0 overflow-hidden p-2 text-zinc-800 transition-opacity ${
              isMinimized ? 'opacity-100 delay-200' : 'opacity-0'
            }`}
          />
        </Button>
      </FileTrigger>
    </div>
  )
})

VisionUploadPictureButton.displayName = 'VisionUploadPictureButton'

export default VisionUploadPictureButton
