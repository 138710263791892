import {useMemo} from 'react'
import {MdImageSearch} from 'react-icons/md'

import {BoundingPoly} from '@atorie/api/vision'

import {BoundingPolyOverlay} from './bounding-poly-overlay'

export interface AnalyzingOverlayProps {
  className?: string
  dotBGColor?: string
  hideAnalyzing?: boolean
  style?: React.CSSProperties
  boundingPoly?: BoundingPoly | null
  hideFrame?: boolean
}

export function AnalyzingOverlay({
  className,
  dotBGColor = 'bg-white',
  hideAnalyzing = false,
  style,
  boundingPoly,
  hideFrame = false,
}: AnalyzingOverlayProps) {
  const dots = useMemo(() => {
    return Array.from({length: 25}, () => ({
      top: Math.random() * 90 + 5,
      left: Math.random() * 90 + 5,
      size: Math.random() * 0.45 + 0.1, // Size between 0.05 and 0.35 rem
      delay: Math.random() * 3, // Delay between 0 and 3 seconds
      duration: Math.random() * 0.5 + 2, // Duration between 2 and 2.5 seconds
    }))
  }, [])

  return (
    <div
      className={`flex size-full items-center justify-center rounded-lg ${className}`}
      style={style}
    >
      <div className="relative size-full overflow-hidden rounded-md bg-transparent">
        {!hideFrame && (
          <>
            {/* Corner borders */}
            <div className="absolute left-0 top-0 size-12 rounded-tl-lg border-l-4 border-t-4 border-zinc-800"></div>
            <div className="absolute right-0 top-0 size-12 rounded-tr-lg border-r-4 border-t-4 border-zinc-800"></div>
            <div className="absolute bottom-0 left-0 size-12 rounded-bl-lg border-b-4 border-l-4 border-zinc-800"></div>
            <div className="absolute bottom-0 right-0 size-12 rounded-br-lg border-b-4 border-r-4 border-zinc-800"></div>
          </>
        )}

        {/* Randomly scattered, pulsating dots */}
        {!hideAnalyzing && (
          <div
            className={`absolute inset-0 transition-opacity duration-300 ${boundingPoly == null ? 'opacity-100' : 'opacity-0'}`}
          >
            {dots.map((dot, i) => (
              <div
                key={i}
                className={`fade-in-out-infinite absolute rounded-full ${dotBGColor}`}
                style={{
                  top: `${dot.top}%`,
                  left: `${dot.left}%`,
                  width: `${dot.size}rem`,
                  height: `${dot.size}rem`,
                  animationDelay: `${dot.delay}s`,
                  animationDuration: `${dot.duration}s`,
                }}
              ></div>
            ))}
            <div className="absolute inset-0 flex items-center justify-center">
              <MdImageSearch size={48} className="mb-1 ml-1 fill-white" />
            </div>
          </div>
        )}

        {/* Bounding poly divs */}
        <div
          className={`absolute inset-0 transition-opacity duration-300 ${boundingPoly != null ? 'opacity-100' : 'opacity-0'}`}
        >
          <BoundingPolyOverlay boundingPoly={boundingPoly} />
        </div>
      </div>
    </div>
  )
}
