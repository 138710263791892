export function Spinner({
  color = 'white',
  size = '24',
}: {
  color?: string
  size?: string
}) {
  return (
    <div
      className="animate-spin rounded-full border-2 border-transparent"
      style={{
        borderTopColor: color,
        borderRightColor: color,
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  )
}
